import { right } from "@popperjs/core"
import React, { useContext, useState, useRef } from 'react'

import SelectSearch from "react-select-search";

import { Link } from 'react-router-dom';



import MoreIcon from '@material-ui/icons/MoreVert';
import { Button } from '@material-ui/core';

import { NavLink } from 'react-router-dom';



export const Navigation = (props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const handleMenuClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLinkClick = () => {
    setIsMenuOpen(false);
  };


  return (
    <>
      <nav id='menu' className='navbar navbar-default navbar-fixed-top'>
        <div className='container'>
          <div className='navbar-header'>
            <button
              type='button'
              className='navbar-toggle collapsed'
              data-toggle='collapse'
              data-target='#bs-example-navbar-collapse-2'
              onClick={handleMenuClick}
            >
              {' '}
              <span className='sr-only'>Toggle navigation</span>{' '}
              <span className='icon-bar'></span>{' '}
              <span className='icon-bar'></span>{' '}
              <span className='icon-bar'></span>{' '}
            </button>
            <div style={{ display: 'inline-block' }}>
              <a className='navbar-brand page-scroll' href='/'>
                <img style={{ height: 50, overflow: 'hidden' }} align="left" src="img/logobg.png" />
                Reader's<br /> &nbsp; Rejoice
              </a>{' '}
            </div>
          </div>

          <div className={`collapse navbar-collapse ${isMenuOpen ? 'show' : ''}`}

            id='bs-example-navbar-collapse-1'
          >
            <ul className='nav navbar-nav navbar-right'>
              <li>
                <a href='/' className='page-scroll' onClick={handleLinkClick}>
                  Home
                </a>
              </li>

              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="/" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Branch
                </a>
                <div id="branchtoggle" className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <a className="dropdown-item" href="/library-boring-canal-road">East Boring Canal Road</a>
                  <div className="dropdown-divider"></div>
                  <a className="dropdown-item" href="/library-boring-road">West Boring Canal Road</a>
                  <div className="dropdown-divider"></div>
                  <a className="dropdown-item" href="/library-kanti-factory-road">Kanti Factory Road</a>
                  <div className="dropdown-divider"></div>
                  <a className="dropdown-item" href="/library-kankarbagh">Doctor's Coloney</a>
                  <div className="dropdown-divider"></div>

                </div>
              </li>
              <li>
                <a href='/#about' className='page-scroll' onClick={handleLinkClick}>
                  About
                </a>
              </li>
              <li>
                <a href='/#services' className='page-scroll' onClick={handleLinkClick}>
                  Services
                </a>
              </li>
              <li>
                <a href='/#portfolio2' className='page-scroll' onClick={handleLinkClick}>
                  Gallery
                </a>
              </li>

              <li>
                <a href='/#testimonials' className='page-scroll' onClick={handleLinkClick}>
                  Testimonials
                </a>
              </li>
              {/*<li>
        <a href='#contactnew' className='page-scroll'>
          Contact
        </a>
      </li> */}
              <li>
                <a href='/#contact' className='page-scroll' onClick={handleLinkClick}>
                  Contact
                </a>
              </li>

              <li>
                <NavLink className='page-scroll' to="/login" >Login</NavLink>
              </li>

            </ul>
          </div>
        </div>

      </nav>

    </>
  )
}
