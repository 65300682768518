import { useState, useEffect } from "react";

import { Navigation } from "./components/navigation";
import { Route, Routes, Switch } from 'react-router-dom';
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import Home from "./components/Home/home";
import Ecbr from "./components/Home/ebcr";
import Wbcr from "./components/Home/wbcr";
import Dcg from "./components/Home/dcg";
import Kfr from "./components/Home/kfr";
import AOS from 'aos';
import { Footer2 } from "./components/footer2";
import Signup from "./components/Signup"
import { Container } from "react-bootstrap"
import { AuthProvider } from "./contexts/AuthContext"

import Dashboard from "./components/Dashboard"
import Login from "./components/Login"
import PrivateRoute from "./components/PrivateRoute"
import ForgotPassword from "./components/ForgotPassword"
import UpdateProfile from "./components/UpdateProfile"
import Otpsignup from "./components/Otpsignup";
import { Products } from "./components/branchnav/Products";
import Register from './components/Register';



import 'aos/dist/aos.css';
import Chome from "./components/Home/chome";
import { CheckIn } from "./components/pages/checkin";
import { LibSearch } from "./components/pages/libsearch";
import { CheckIn2 } from "./components/pages/checkin2";
import {PrivacyPolicies} from "./components/pages/privacypolicies"

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  AOS.init({
    once: true,
    delay: 50,
    duration: 500,
    easing: 'ease-in-out',
  });
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
    <Navigation />
    <AuthProvider>
      <Routes>
                <Route exact path="/" element={< Home />} />
                <Route path="/dash" element={< Dashboard />} />
                <Route path="/private" element={< PrivateRoute />} />
                <Route path="/update-profile" element={< UpdateProfile />} />
                {/*
                  <Route path="/signup" element={< Signup />} />
                <Route path="/login" element={< Login />} />
                <Route path="/forgot-password" element={< ForgotPassword />} />
                <Route path="/otpsignup" element={< Otpsignup />} />
                  */}
                <Route path="/branchselect" element={<Products data={landingPageData.Products} />} />
                <Route path="/register" element={< Register />} />
                <Route path="/product" element={<Products data={landingPageData.Products}/>} />
                <Route path="/search" element={< LibSearch />} />
                
                <Route path="/privacypolicy" element={<PrivacyPolicies />} />

                <Route path="/library-boring-canal-road" element={<Ecbr />} />
                <Route path="/library-boring-road" element={<Wbcr />} />
                <Route path="/library-kankarbagh" element={<Dcg />} />
                <Route path="/library-kanti-factory-road" element={<Kfr />} />
                <Route path="/chome" element={<Chome />} />
                <Route path="/checkin" element={<CheckIn />} />
                <Route path="/checkin2" element={<CheckIn2 />} />
                
            </Routes>
            </AuthProvider>
           
            <Footer2 data={landingPageData.Footer} />
    
    </div>
  );
};

export default App;
